import {
  AvailableAsPageItems,
  Card,
  MatchupCard,
  Rail,
  Ref,
  VideoCard,
  WatchVideoRail,
  WebWatchPage,
} from '@grandstand/presentation-models'
import { ComponentType } from '@grandstand/presentation-models/dist/types/components/_abstract'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ApiClient } from '../newPackages/ApiClient'
import { ConfigServiceContext, useContextUnconditionally } from '../services/config/ConfigService'
import { UserServiceContext } from '../services/user/UserService'
import { ErrorDetails } from './useBitmovinVideo'

export type UseWebWatchPage = {
  data?: WebWatchPage
  error?: ErrorDetails
}

export const useWebWatchPage = (): UseWebWatchPage => {
  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  const userService = useContext(UserServiceContext)
  const [data, setData] = useState<WebWatchPage | undefined>(undefined)
  const [pollInterval, setPollInterval] = useState<number | undefined>(undefined)
  const [error, setError] = useState<ErrorDetails | undefined>(undefined)

  const getData = useCallback(async () => {
    try {
      const userToken = userService.currentUser?.user_token
      if (userToken === undefined) {
        return
      }
      const url = currentConfig.API.app_data.root
      if (url === undefined) {
        return
      }
      const response = await ApiClient.apiFetch(url, {
        method: 'GET',
      })
      const body = await response.json()
      if (!response.ok) {
        setData(undefined)
        setError(body as ErrorDetails)
      } else {
        setData(body as WebWatchPage)
        setError(undefined)
      }
    } catch (e) {
      console.error('Unable to get watch page', e)
      setError({ code: 'network_error' } as ErrorDetails)
    }
  }, [currentConfig.API.app_data.root, userService.currentUser?.user_token])

  useEffect(() => {
    getData()
  }, [getData])

  useEffect(() => {
    const getPollIntervalForWebWatchPage = (): number | undefined => {
      try {
        if (!data) {
          return undefined
        }

        const flattenContentToVideoDates = (
          acc: Date[],
          curr: WebWatchPage | Ref<AvailableAsPageItems> | Ref<Card>
        ): Date[] => {
          if (curr.type == ComponentType.WatchPage || curr.type == ComponentType.WebWatchPage) {
            const currWatchPage = curr as unknown as WebWatchPage
            for (const currWatchPageItem of currWatchPage.items) {
              flattenContentToVideoDates(acc, currWatchPageItem)
            }
          }
          if (curr.type == ComponentType.Rail) {
            const currRail = curr as unknown as Rail
            for (const currRailItem of currRail.items) {
              flattenContentToVideoDates(acc, currRailItem)
            }
          }
          if (curr.type == ComponentType.WatchVideoRail) {
            const currWatchVideoRail = curr as unknown as WatchVideoRail
            for (const currWatchVideoRailItem of currWatchVideoRail.items) {
              flattenContentToVideoDates(acc, currWatchVideoRailItem)
            }
          }
          if (curr.type == ComponentType.VideoCard) {
            const videoCard = curr as unknown as VideoCard
            if (videoCard.content.video) {
              if (videoCard.content.video.start_date) {
                acc.push(new Date(videoCard.content.video.start_date))
              }
              if (videoCard.content.video.end_date) {
                acc.push(new Date(videoCard.content.video.end_date))
              }
            }
          }
          if (curr.type == ComponentType.MatchupCard) {
            const matchupCard = curr as unknown as MatchupCard
            if (matchupCard.content.video) {
              if (matchupCard.content.video.start_date) {
                acc.push(new Date(matchupCard.content.video.start_date))
              }
              if (matchupCard.content.video.end_date) {
                acc.push(new Date(matchupCard.content.video.end_date))
              }
            }
          }
          return acc
        }

        const videoDates = flattenContentToVideoDates([], data)
        const now = new Date()
        const futureVideoDates = videoDates.filter((videoDate) => videoDate.getTime() > now.getTime())
        let earliestFutureVideoDate: Date | null = null
        for (const futureVideoDate of futureVideoDates) {
          if (!earliestFutureVideoDate || futureVideoDate.getTime() < earliestFutureVideoDate.getTime()) {
            earliestFutureVideoDate = futureVideoDate
          }
        }

        if (!earliestFutureVideoDate) {
          return undefined
        }

        return earliestFutureVideoDate.getTime() - now.getTime()
      } catch {
        return undefined
      }
    }

    setPollInterval(getPollIntervalForWebWatchPage())
  }, [data, currentConfig.API.services.content_services.page_auto_refresh_interval, getData])

  // Wait ${pollInterval}ms to call getData (if pollInterval is defined)
  useEffect(() => {
    if (pollInterval === undefined) {
      return
    }
    const timeout = setTimeout(getData, pollInterval)
    return () => {
      clearTimeout(timeout)
    }
  }, [pollInterval, getData])

  return {
    data,
    error,
  }
}
